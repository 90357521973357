'use client'

import useAbTest from 'hooks/useAbTest'

import Banner from './Banner'
import CompliantFeesApplyBanner from './CompliantFeesApplyBanner'

type Props = {
  suffix?: JSX.Element
  prefix?: JSX.Element
}

const ShippingFeesAppliedBanner = (props: Props) => {
  const shippingFeesInfoBannerV1 = useAbTest({
    abTestName: 'info_banner_shipping_fees_apply_feed',
  })
  const compliantShippingFeesBannerTest = useAbTest({
    abTestName: 'info_banner_shipping_feed_sticky_v2',
  })

  if (shippingFeesInfoBannerV1) return <Banner {...props} />
  if (compliantShippingFeesBannerTest) return <CompliantFeesApplyBanner {...props} />

  return null
}

export default ShippingFeesAppliedBanner
