'use client'

import useAbTest from 'hooks/useAbTest'

import { AbTestVariant } from 'constants/abtest'
import { Screen } from 'constants/tracking/screens'

import useSession from 'hooks/useSession'

import DismissibleStickyBanner from './DismissibleStickyBanner'

type Props = {
  suffix?: JSX.Element
  prefix?: JSX.Element
}

const CompliantFeesApplyBanner = ({ prefix, suffix }: Props) => {
  const { screen, user } = useSession()
  const isLoggedIn = !!user
  const compliantShippingBannerTest = useAbTest({
    abTestName: 'info_banner_shipping_feed_sticky_v2',
    shouldTrackExpose: screen === Screen.NewsFeed,
  })

  if (
    !isLoggedIn ||
    !compliantShippingBannerTest ||
    compliantShippingBannerTest.variant === AbTestVariant.Off
  ) {
    return null
  }

  return (
    <>
      {prefix}
      <DismissibleStickyBanner />
      {suffix}
    </>
  )
}

export default CompliantFeesApplyBanner
