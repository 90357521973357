import {
  prepareClosetPromotionOrder as prepareClosetPromotionOrderRequest,
  getClosetPromotionOrder as getClosetPromotionOrderRequest,
} from 'data/api'
import {
  transformGetClosetPromotionOrderResponse,
  transformPrepareClosetPromotionOrderResponse,
} from 'data/api/transformers/response'

import useFetch from 'hooks/useFetch'

const useClosetPromotionOrder = () => {
  const {
    fetch: prepareClosetPromotionOrder,
    transformedData: preparedOrder,
    isLoading: isOrderPrepareLoading,
    error: prepareOrderError,
  } = useFetch(prepareClosetPromotionOrderRequest, transformPrepareClosetPromotionOrderResponse)
  const {
    fetch: getClosetPromotionOrder,
    transformedData: fetchedOrder,
    isLoading: isOrderGetLoading,
    error: getOrderError,
  } = useFetch(getClosetPromotionOrderRequest, transformGetClosetPromotionOrderResponse)

  return {
    order: preparedOrder || fetchedOrder,
    isOrderLoading: isOrderPrepareLoading || isOrderGetLoading,
    orderError: prepareOrderError || getOrderError,
    prepareClosetPromotionOrder,
    getClosetPromotionOrder,
  }
}

export default useClosetPromotionOrder
